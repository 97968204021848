
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accountxXB6MposMQMeta } from "/vercel/path0/app/pages/account.vue?macro=true";
import { default as editjzuvMDYRpJMeta } from "/vercel/path0/app/pages/edit.vue?macro=true";
import { default as _91id_93kfJ2QTyUUKMeta } from "/vercel/path0/app/pages/import/tiktok/[id].vue?macro=true";
import { default as indexF9NHiULORkMeta } from "/vercel/path0/app/pages/import/tiktok/index.vue?macro=true";
import { default as indexiGEjlfgJ29Meta } from "/vercel/path0/app/pages/index.vue?macro=true";
import { default as _91id_93WJapUn4djfMeta } from "/vercel/path0/app/pages/schedules/[id].vue?macro=true";
import { default as index9x6tL0vPCPMeta } from "/vercel/path0/app/pages/schedules/index.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/app/pages/account.vue")
  },
  {
    name: "edit",
    path: "/edit",
    component: () => import("/vercel/path0/app/pages/edit.vue")
  },
  {
    name: "import-tiktok-id",
    path: "/import/tiktok/:id()",
    component: () => import("/vercel/path0/app/pages/import/tiktok/[id].vue")
  },
  {
    name: "import-tiktok",
    path: "/import/tiktok",
    component: () => import("/vercel/path0/app/pages/import/tiktok/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/app/pages/index.vue")
  },
  {
    name: "schedules-id",
    path: "/schedules/:id()",
    component: () => import("/vercel/path0/app/pages/schedules/[id].vue")
  },
  {
    name: "schedules",
    path: "/schedules",
    component: () => import("/vercel/path0/app/pages/schedules/index.vue")
  }
]