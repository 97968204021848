import handleArrayWIthProperties_ypljaokOlv from "/vercel/path0/app/plugins/handleArrayWIthProperties.ts";
import revive_payload_client_LGibr6oguq from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_AIfJTiAcCE from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@types+n_xiu673mdhsv6l2tj7s4jb2aa6q/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_TsQYBzCrSL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zYUsiEwN4s from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_6TkDmjxxhx from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@https+++github.com+dan-hale+supabase+releases+download+v1.4.6-0+nuxtjs-supabase-1.4.6-0.tgz/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_COI2HLOLL5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yj4X05ISTQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_asUAHFEhzN from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_CqcUSs99vX from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_s0WnRqvMSK from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_mEW6enqGXL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_rvEivuZbIL from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_@upstash+redis@1.34.3_@vercel+kv@3.0.0_vli2zmuc4tfotovbzrd3hhkoma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_z6fytUHucR from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_sa_mb47o2atkv3nhfrplwrcwyldqu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_redirect_SsyCiUwvUY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@https+++github.com+dan-hale+supabase+releases+download+v1.4.6-0+nuxtjs-supabase-1.4.6-0.tgz/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import unocss_RAHspWIKy3 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import vuetify_no_client_hints_FYLe1qJyDC from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.32.0_typescript@5.7.3_vite@6.0.11_@types+n_xiu673mdhsv6l2tj7s4jb2aa6q/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import custom_icons_039LGJjGSP from "/vercel/path0/app/plugins/custom-icons.ts";
import directives_UCglB6puIs from "/vercel/path0/app/plugins/directives.ts";
import vuetify_nuxt_plugin_client_moE2XX1Cig from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  handleArrayWIthProperties_ypljaokOlv,
  revive_payload_client_LGibr6oguq,
  vuetify_icons_AIfJTiAcCE,
  unhead_TsQYBzCrSL,
  router_zYUsiEwN4s,
  supabase_client_6TkDmjxxhx,
  payload_client_COI2HLOLL5,
  navigation_repaint_client_yj4X05ISTQ,
  check_outdated_build_client_asUAHFEhzN,
  view_transitions_client_CqcUSs99vX,
  chunk_reload_client_s0WnRqvMSK,
  cross_origin_prefetch_client_mEW6enqGXL,
  components_plugin_zlvi6dcIsi,
  prefetch_client_rvEivuZbIL,
  plugin_z6fytUHucR,
  auth_redirect_SsyCiUwvUY,
  unocss_RAHspWIKy3,
  vuetify_no_client_hints_FYLe1qJyDC,
  custom_icons_039LGJjGSP,
  directives_UCglB6puIs,
  vuetify_nuxt_plugin_client_moE2XX1Cig
]