<script setup lang="ts">
import { provideUseId } from '@headlessui/vue'
import { Analytics } from '@vercel/analytics/nuxt'

const user = useSupabaseUser()
provideUseId(() => useId().replace(/[-:]/, '_'))
</script>

<template>
  <NuxtLayout :name="user ? 'default' : 'empty'">
    <Analytics />
    <NuxtPage />
  </NuxtLayout>
</template>
